import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@constants/project';
import { CustomizedSider, CustomizedMenu } from './styles';
import { Menu } from 'antd';
import { WORK_CATEGORY_PATH } from '@constants/setting';

const Sider = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onSelect = ({ key }: { key: string }) => {
    navigate(key === WORK_CATEGORY_PATH ? `${key}` : `/${key}?page=${DEFAULT_PAGE}&size=${DEFAULT_PAGE_SIZE}`);
  };
  const [selectedKey] = pathname.slice(1).split('/');

  return (
    <CustomizedSider>
      <CustomizedMenu
        mode="inline"
        onSelect={onSelect}
        defaultSelectedKeys={[pathname === WORK_CATEGORY_PATH ? WORK_CATEGORY_PATH : `${selectedKey as string}`]}
      >
        <Menu.Item key="project">
          <span>프로젝트 관리</span>
        </Menu.Item>
        <Menu.Item key="user">
          <span>회원 관리</span>
        </Menu.Item>
        <Menu.Item key="/setting/pj">
          <span>설정 관리</span>
        </Menu.Item>
      </CustomizedMenu>
    </CustomizedSider>
  );
};

export default Sider;
