import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useUserActions } from '../../store/store';
import { getUserInfoData } from '../../components/template/quries';
import api from '@utils/api';

const userLogin = (info: { email: string; password: string }) => {
  const { email, password } = info;
  return api.post('/auth/login', { email, password });
};

export const useLogin = () => {
  const navigate = useNavigate();
  const { setUserInfo, setToken } = useUserActions();

  return useMutation(userLogin, {
    onSuccess: async ({ data }) => {
      setToken(data.access_token);
      const userInfo = await getUserInfoData();
      setUserInfo(userInfo);
      navigate('/project');
    },
    onError: (error: AxiosError) => {
      const { message: errorMessage } = error.response?.data;
      message.error({ content: errorMessage, key: 'error' });
    },
  });
};
