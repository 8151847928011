import { Menu, Dropdown, Space } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@constants/project';
import {
  CustomizedHeader,
  LogoWrapper,
  HeaderLogo,
  NavTitle,
  HeaderInfoWrapper,
  UserInfo,
  DropDownIcon,
} from './styles';
import { useUserInfo } from '../../store/store';

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userInfo = useUserInfo();

  const navTitle = (pathname: string) => {
    if (pathname.startsWith('/project')) {
      return '프로젝트 관리';
    } else if (pathname.startsWith('/user')) {
      return '회원 관리';
    } else {
      return null;
    }
  };

  const handleSignOut = () => {
    window.localStorage.removeItem('userInfo');
    navigate('/login');
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleSignOut} style={{ textAlign: 'center' }}>
        로그아웃
      </Menu.Item>
      <Menu.Divider />
    </Menu>
  );

  return (
    <CustomizedHeader>
      <LogoWrapper onClick={() => navigate(`/project?page=${DEFAULT_PAGE}&size=${DEFAULT_PAGE_SIZE}`)}>
        <HeaderLogo></HeaderLogo>
      </LogoWrapper>

      <HeaderInfoWrapper>
        <NavTitle>{navTitle(pathname)}</NavTitle>
        <Dropdown overlay={menu} trigger={['click']}>
          <UserInfo>
            <Space>
              {userInfo?.email}
              <DropDownIcon></DropDownIcon>
            </Space>
          </UserInfo>
        </Dropdown>
      </HeaderInfoWrapper>
    </CustomizedHeader>
  );
};

export default Header;
