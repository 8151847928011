import { useQuery, useMutation, useQueryClient } from 'react-query';
import { tableFuncParamsType, useTerminateProjectGenericType, InspectionCheckList } from './types';
import { PROJECT_STATUS_TERMINATION } from '@constants/project';
import { message } from 'antd';
import api from '@utils/api';

const getMasterTableData = async (page: number, size: number) => {
  const { data } = await api.get(`/project-code-creation?page=${page}&limit=${size}`);
  return data;
};

export const usePaginatedMasterTableData = ({ page, size }: { page: number; size: number }) => {
  return useQuery(['project-master-list', page, size], () => getMasterTableData(page, size), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const resendCode = (id: number) => {
  return api.post(`/project-code-creation/${id}/resend`);
};

export const useCodeResend = () => {
  return useMutation(resendCode, {
    onSuccess: () => {
      message.success('코드가 재전송 되었습니다.');
    },
  });
};

const getTableData = async ({ status, page, size, search }: tableFuncParamsType) => {
  const { data } = await api.get(`/projects/${status}/status?page=${page}&limit=${size}&search=${search}`);
  return data;
};

export const usePaginatedTableData = ({ status, page, size, search }: tableFuncParamsType) => {
  return useQuery(
    ['project-table-list', status, page, size, search],
    () => getTableData({ status, page, size, search }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: 0,
      refetchOnReconnect: false,
    }
  );
};

const terminateProject = (id: number) => {
  return api.patch(`/projects/${id}`, { status: `${PROJECT_STATUS_TERMINATION}` });
};

export const useTerminateProject = ({ status, page, size, search }: tableFuncParamsType) => {
  const queryClient = useQueryClient();

  return useMutation(terminateProject, {
    onSuccess: ({ data }) => {
      message.success('선택한 프로젝트가 종료되었습니다.');

      queryClient.setQueryData<useTerminateProjectGenericType>(
        ['project-table-list', status, page, size, search],
        (oldQueryData) => {
          const updatedList = oldQueryData?.list?.filter(({ id }: { id: number }) => id !== data.id);
          const updatedCount = oldQueryData?.count ? oldQueryData?.count : 0;

          return {
            list: updatedList,
            count: updatedCount,
          };
        }
      );
    },
    onError: () => {
      message.error('프로젝트 종료에 실패하였습니다. 다시 시도해주세요.');
    },
  });
};

const deleteProject = async (id: number) => {
  const { data } = await api.delete(`/projects/${id}`);
  return data;
};

export const useDeleteProject = ({ status, page, size, search }: tableFuncParamsType) => {
  const queryClient = useQueryClient();

  return useMutation(deleteProject, {
    onSuccess: () => {
      message.success('선택한 프로젝트가 삭제되었습니다.');
      queryClient.refetchQueries(['project-table-list', status, page, size, search]);
    },
    onError: () => {
      message.error('프로젝트 삭제에 실패하였습니다. 다시 시도해주세요.');
    },
  });
};

const getInspectionCheckList = async (id: number) => {
  const { data } = await api.get(`projects/${id}/inspection-checklist`);
  return data as Array<InspectionCheckList>;
};

export const useGetInspectionCheckList = (id: number) => {
  return useQuery([`inspection-check-list-${id}`], () => getInspectionCheckList(id), {
    enabled: !!id,
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) =>
      data.map(({ id, title, work_category, detail_category, category_yn, construction, criterion }) => ({
        Id: id,
        공사: construction,
        공종: work_category,
        세부공종: detail_category,
        항목여부: category_yn,
        검사항목: title,
        검사기준: criterion,
      })),
  });
};
