import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@constants/project';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const ProjectTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onChange = (key: string) => {
    navigate(`${key}?page=${DEFAULT_PAGE}&size=${DEFAULT_PAGE_SIZE}`);
  };

  return (
    <>
      <Tabs type="card" onChange={onChange} activeKey={pathname}>
        <TabPane tab="진행중" key="/project"></TabPane>
        <TabPane tab="중지" key="/project/stop"></TabPane>
        <TabPane tab="종료" key="/project/termination"></TabPane>
        <TabPane tab="삭제예정" key="/project/expected"></TabPane>
        <TabPane tab="마스터생성" key="/project/master"></TabPane>
      </Tabs>
      <Outlet></Outlet>
    </>
  );
};

export default ProjectTabs;
