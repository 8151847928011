import { useQuery, useMutation, useQueryClient } from 'react-query';
import { USER_JOINED } from '@constants/users';
import { tableFuncParamsType, deleteUserParameterType } from './types';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import api from '@utils/api';

const getJoinedUserData = async ({ page, size, search }: tableFuncParamsType) => {
  const { data } = await api.get(`/members?page=${page}&limit=${size}&search=${search}`);
  return data;
};

export const usePaginatedJoinTableData = ({
  page,
  size,
  search,
  enabled,
}: tableFuncParamsType & { enabled: boolean }) => {
  return useQuery(['user-join', page, size, search], () => getJoinedUserData({ page, size, search }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled,
  });
};

const getDeletedUser = async () => {
  const { data } = await api.get(`/user-secessions/csv`);
  return data;
};

const getJoinedUser = async () => {
  const { data } = await api.get(`/members/csv`);
  return data;
};

export const useDownloadCSV = (type: string) => {
  return useQuery(['user-csv'], () => (type === USER_JOINED ? getJoinedUser() : getDeletedUser()), {
    enabled: false,
    initialData: [],
  });
};

const getDeletedUserData = async ({ page, size, search }: tableFuncParamsType) => {
  const { data } = await api.get(`/user-secessions?page=${page}&limit=${size}&search=${search}`);
  return data;
};

export const usePaginatedDeleteTableData = ({
  page,
  size,
  search,
  enabled,
}: tableFuncParamsType & { enabled: boolean }) => {
  return useQuery(['user-delete', page, size, search], () => getDeletedUserData({ page, size, search }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

const deleteUserAccount = async (id: number) => {
  const { data } = await api.delete(`/user-secessions/${id}`);
  return data;
};

export const useDeleteUserAccount = ({ page, size, search }: tableFuncParamsType) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUserAccount, {
    onSuccess: () => {
      message.success('선택한 계정이 삭제되었습니다.');
      queryClient.refetchQueries(['user-delete', page, size, search]);
    },
    onError: (error: AxiosError) => {
      message.error({ content: error.response?.data.message, key: 'error' });
    },
  });
};

const uploadCSV = async ({ file }: { file: string | Blob | RcFile }) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await api.post('/members/upload', formData, { headers: { 'content-type': 'multipart/form-data' } });
  return data;
};

export const useUploadCSV = ({ page, size, search }: tableFuncParamsType) => {
  const queryClient = useQueryClient();
  return useMutation(uploadCSV, {
    onSuccess: () => {
      message.success({ content: '추가되었습니다.', key: 'success' });
      queryClient.invalidateQueries(['user-join', page, size, search]);
    },
    onError: (error: AxiosError) => {
      message.error({ content: error.response?.data.message, key: 'error' });
    },
  });
};

const withdrawalUser = async ({ userId }: deleteUserParameterType) => {
  const { data } = await api.delete(`/members/${userId}`);
  return data;
};

export const useWithdrawalUser = ({ page, size, search }: { page: number; size: number; search: string }) => {
  const queryClient = useQueryClient();

  return useMutation(withdrawalUser, {
    onSuccess: () => {
      message.success({ content: '탈퇴처리 되었습니다.', key: 'success' });
      queryClient.invalidateQueries(['user-join', page, size, search]);
    },
    onError: (error: AxiosError) => {
      message.error({ content: error.response?.data.message, key: 'error' });
    },
  });
};

const resetPassword = async (userId: number) => {
  const { data } = await api.patch(`/members/${userId}/reset-password`);
  return data;
};

export const useResetPassword = () => {
  return useMutation(resetPassword, {
    onSuccess: () => {
      message.success({ content: '초기화 되었습니다.', key: 'success' });
    },
    onError: (error: Error) => {
      message.error({ content: error.message, key: 'error' });
    },
  });
};
