import { useSerivcePlanData, useRestartProject } from './quries';
import { useNavigate } from 'react-router-dom';
import { modalType } from '@components/common/types';
import { Modal, Form, Input, Radio } from 'antd';
import { ProjectInfoType } from '@components/project/types';
import dayjs from 'dayjs';
import { DatePicker } from '@components/common';

const RestartModal = ({
  title,
  visible,
  onCancel,
  size,
  projectInfo,
}: { page: number; size: number; projectInfo: ProjectInfoType } & modalType) => {
  const [form] = Form.useForm();
  const { data: servicePlanData } = useSerivcePlanData();
  const navigate = useNavigate();
  const handleMutateSuccess = () => {
    onCancel();
    navigate(`/project?page=1&size=${size}`);
  };

  const { mutate: restartMutate } = useRestartProject(handleMutateSuccess);

  const onOk = async () => {
    const result = await form.validateFields();
    restartMutate({
      ...result,
      id: projectInfo.projectId,
      total_size: result.total_size * Math.pow(1024, 3),
      endDate: dayjs(result.endDate).format('YYYY-MM-DD'),
    });
  };

  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form
        form={form}
        initialValues={{
          classification: 'A00',
          plan: 9,
          people: projectInfo.memberCount,
          total_size: ((projectInfo.storageSize as number) / (1024 * 1024 * 1024)).toFixed(0),
          endDate: dayjs(projectInfo.endDate),
        }}
        layout="vertical"
        name="add_form_modal"
      >
        <Form.Item name="plan" label="서비스 종류">
          <Radio.Group>
            {servicePlanData?.map(({ id, title }: { id: number; title: string }) => (
              <Radio.Button key={id} value={id}>
                {title}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="people"
          label="인원"
          rules={[
            {
              required: true,
              message: '인원수를 입력하세요.',
            },
            {
              pattern: /^[0-9]*$/,
              message: '숫자로 인원 수를 입력해주세요.',
            },
          ]}
        >
          <Input placeholder="프로젝트 참여 인원 수"></Input>
        </Form.Item>
        <Form.Item
          name="endDate"
          label="사용기간"
          rules={[
            {
              required: true,
              message: '사용기간을 선택해주세요.',
            },
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="total_size" label="용량">
          <Input placeholder="GB" type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RestartModal;
