import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ko_KR';
import './App.less';
import LoginPage from '@pages/LoginPage';
import ProjectsPage from '@pages/ProjectsPage';
import { ProjectTable, ProjectMasterTable } from '@components/project';
import UserManagementPage from '@pages/UserManagementPage';
import UserJoinedTable from '@components/userManagement/UserJoinedTable';
import PageNotFoundPage from '@pages/PageNotFoundPage';
import Auth from '@components/Auth';
import {
  PROJECT_STATUS_ONGOING,
  PROJEC_STATUS_SUPENSION,
  PROJECT_STATUS_TERMINATION,
  PROJECT_STATUS_EXPECTED,
} from '@constants/project';
import { USER_JOINED, USER_DEACTIVATED } from '@constants/users';
import SettingPage from '@pages/SettingPage';
import WorkCategoryComponent from '@components/setting/workCategory';
import ServiceComponent from '@components/setting/service';
import PJComponent from '@components/setting/pj';

const GlobalStyle = createGlobalStyle`
  ${reset}
`;

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={locale}>
        <GlobalStyle></GlobalStyle>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route element={<Auth />}>
              <Route path="/project" element={<ProjectsPage />}>
                <Route path="" element={<ProjectTable status={PROJECT_STATUS_ONGOING} />}></Route>
                <Route path="stop" element={<ProjectTable status={PROJEC_STATUS_SUPENSION} />}></Route>
                <Route path="termination" element={<ProjectTable status={PROJECT_STATUS_TERMINATION} />}></Route>
                <Route path="expected" element={<ProjectTable status={PROJECT_STATUS_EXPECTED} />}></Route>
                <Route path="master" element={<ProjectMasterTable />}></Route>
              </Route>
              <Route path="/user" element={<UserManagementPage />}>
                <Route path="" element={<UserJoinedTable type={USER_JOINED} />}></Route>
                <Route path="delete" element={<UserJoinedTable type={USER_DEACTIVATED} />}></Route>
              </Route>
              <Route path="/setting" element={<SettingPage />}>
                <Route path="pj" element={<PJComponent />} />
                <Route path="work_category" element={<WorkCategoryComponent />} />
                <Route path="service_use" element={<ServiceComponent />} />
              </Route>
              <Route path="*" element={<PageNotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right"></ReactQueryDevtools>
    </QueryClientProvider>
  );
}

export default App;
